import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { YouTubeEmbed } from "react-social-media-embed";
import Button from "../common/Button";
import { Context } from "../context/MainContext";
import { deletePortfolio, fetchPortfolios } from "../api/dashboardApi";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import ReactModal from "react-modal";

const Portfolio = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [portfolioItem, setPortfolioItem] = useState({});
  const [websites, setWebsites] = useState([]);
  const [videos, setVideos] = useState([]);
  const [logosTop, setLogosTop] = useState([]);
  const [mobileApps, setMobileApps] = useState([]);
  const [podcasts, setPodcasts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [logos, setLogos] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  //   const websites = [
  //     {
  //       name: "BHIS",
  //       img: "http://localhost:5000/uploads/1722268368767_03.jpg",
  //       url: "https://bhis.by.gov.ng",
  //     },
  //     {
  //       name: "NAIJA HANDS ",
  //       img: "http://localhost:5000/uploads/1722268368767_03.jpg",
  //       url: "https://naijahands.com/",
  //     },
  //   ];

  //   const videos = [
  //     "https://youtu.be/uMQHa3jsSqE?si=SGN8HkQFAtysEtGV",
  //     "https://youtu.be/3R0YKN1MwKk?si=-l-pT0oed7t1fScN",
  //   ];

  useEffect(() => {
    const handleFetchPortfolio = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchPortfolios(user?.accessToken);

          const allWeb = res.data.filter((curr) => curr?.type === "website");

          const promoVids = res.data.filter(
            (curr) => curr?.type === "promoVid"
          );

          const podcast = res.data.filter((curr) => curr?.type === "podcast");

          const logoTop = res.data.filter((curr) => curr?.type === "top");

          const logoDesign = res.data.filter(
            (curr) => curr?.type === "logoDesign"
          );

          const allmobApp = res.data.filter(
            (curr) => curr?.type === "mobileApp"
          );

          const allBrands = res.data.filter((curr) => curr?.type === "design");

          setWebsites(allWeb);
          setVideos(promoVids);
          setLogosTop(logoTop);
          setMobileApps(allmobApp);
          setPodcasts(podcast);
          setBrands(allBrands);
          setLogos(logoDesign);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchPortfolio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, refresh]);

  const ActionButton = ({ item }) => {
    return (
      <div className="portfolio__main__product__list__item__action">
        <Link to={"/dashboard/portfolio/edit/" + item?.id}>
          <i className="feather ft-edit"></i>
        </Link>
        <i
          className="ti-trash del"
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => {
            setModalIsOpen(true);
            setPortfolioItem(item);
          }}
        ></i>
      </div>
    );
  };

  const handleDelete = async () => {
    if (user?.id) {
      setLoading(true);

      try {
        let data = {};
        if (portfolioItem?.image) {
          data = {
            image: portfolioItem?.image,
          };
        }

        await deletePortfolio(user?.accessToken, portfolioItem?.id, data);

        toast.success("Blog deleted successfully");
        setModalIsOpen(false);
        setLoading(false);
        setRefresh((curr) => !curr);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <div className="portfolio">
      <ReactModal
        className="modalMain"
        overlayClassName="modal-overlayCenter"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Cart Modal"
      >
        <div className="tableMenu__modal">
          <h4 style={{ marginBottom: 10 }}>Are you sure</h4>
          <p>
            You are about to delete a portfolio, this process cannot be undone
          </p>
          <div className="tableMenu__modal__btn">
            <button
              className="tableMenu__modal__btn__item"
              onClick={() => setModalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="tableMenu__modal__btn__item delete"
            >
              {loading ? <Loading button={true} /> : "Delete"}
            </button>
          </div>
        </div>
      </ReactModal>
      <div className="blog__top">
        <Link to={"/dashboard/portfolio/add"}>
          <Button height="40px">
            ADD PORTFOLIO <i className="ti-plus"></i>
          </Button>
        </Link>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="portfolio__main">
          <div className="portfolio__main__product">
            <h2>Top Portfolios Worked with</h2>

            <div className="portfolio__main__product__list alt">
              {logosTop.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className="portfolio__main__product__list__item"
                  >
                    <img src={item?.image} alt="" />
                    <ActionButton item={item} />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="portfolio__main__product">
            <h2>Website</h2>

            <div className="portfolio__main__product__list">
              {websites.map((item) => {
                return (
                  <div
                    key={item?.name}
                    className="portfolio__main__product__list__item"
                  >
                    {item?.url ? (
                      <a
                        href={item?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div className="portfolio__main__product__list__item__img">
                          <img src={item?.image} alt="" />
                        </div>
                      </a>
                    ) : (
                      <div className="portfolio__main__product__list__item__img">
                        <img src={item?.image} alt="" />
                      </div>
                    )}

                    {item?.url ? (
                      <a
                        href={item?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <h3 className="linkPort">
                          {item?.name}{" "}
                          <i
                            className="ti-link"
                            style={{ fontSize: 15, marginLeft: 5 }}
                          ></i>
                        </h3>
                      </a>
                    ) : (
                      <h3>{item?.name}</h3>
                    )}
                    <ActionButton item={item} />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="portfolio__main__product">
            <h2>Mobile Application</h2>

            <div className="portfolio__main__product__list">
              {mobileApps.map((item) => {
                return (
                  <div
                    key={item?.name}
                    className="portfolio__main__product__list__item"
                  >
                    <img src={item?.image} alt="" />
                    <h3>{item?.name}</h3>
                    <ActionButton item={item} />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="portfolio__main__product">
            <h2>Promotional Video Ads</h2>

            <div className="portfolio__main__product__list">
              {videos.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className="portfolio__main__product__list__item"
                  >
                    <div>
                      <YouTubeEmbed
                        url={item?.url}
                        width={"100%"}
                        height={220}
                      />
                    </div>
                    <ActionButton item={item} />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="portfolio__main__product">
            <h2>Business Podcast</h2>

            <div className="portfolio__main__product__list">
              {podcasts.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className="portfolio__main__product__list__item"
                  >
                    <div>
                      <YouTubeEmbed
                        url={item?.url}
                        width={"100%"}
                        height={220}
                      />
                    </div>
                    <ActionButton item={item} />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="portfolio__main__product">
            <h2>Product Branding & UI/UX design</h2>
            {/* <p>
                  Create a system to communicate your brand's identity
                  consistently and build trust.
                </p> */}

            <div className="portfolio__main__product__list">
              {brands.map((item, i) => {
                return (
                  <div
                    key={item?.name}
                    className="portfolio__main__product__list__item"
                  >
                    <img
                      style={{ height: i === 8 ? "400px" : "auto" }}
                      src={item?.image}
                      alt=""
                    />
                    <h3>{item?.name}</h3>
                    <ActionButton item={item} />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="portfolio__main__product">
            <h2>Logo Design</h2>

            <div
              className="portfolio__main__product__list alt"
              style={{ marginBottom: 80 }}
            >
              {logos.map((item) => {
                return (
                  <div
                    key={item?.id}
                    className="portfolio__main__product__list__item"
                  >
                    <img src={item?.image} alt="" />
                    <ActionButton item={item} />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Portfolio;
