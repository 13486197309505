import React, { useEffect, useState } from "react";
import { Input, Textarea } from "./styledComponent/formInputs";
import { toast } from "react-toastify";
import { updateService } from "../api/dashboardApi";
import Button from "../common/Button";
import Loading from "./Loading";

const ServiceItem = ({ item, user, setRefresh }) => {
  const [edit, setEdit] = useState(false);
  const [disc, setDisc] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle(item?.title);
    setDisc(item?.disc);
  }, [item]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let data = { title, disc };

      await updateService(item?.id, data, user?.accessToken);

      setLoading(false);
      toast.success("Saved successfully");
      setEdit(false);
      setRefresh((curr) => !curr);
    } catch (err) {
      setLoading(false);
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      toast.error(message);
    }
  };

  return (
    <div className="service__main__list__item">
      {!edit ? (
        <>
          <h5>{item?.title}</h5>
          <p>{item?.disc}</p>
        </>
      ) : (
        <div className="service__main__list__item__form">
          <Input
            style={{ marginBottom: 10 }}
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Name"
          />
          <Textarea
            type="text"
            value={disc}
            onChange={(e) => setDisc(e.target.value)}
            placeholder="Text"
            rows={3}
          ></Textarea>
        </div>
      )}

      <div className="service__main__list__item__action">
        <i
          className="feather ft-edit"
          onClick={() => setEdit((curr) => !curr)}
        ></i>

        {edit && (
          <Button width="100px" height="40px" onClick={handleSubmit}>
            {loading ? <Loading button={true} /> : "Save"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default ServiceItem;
