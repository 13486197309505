import { useContext, useState } from "react";
import Button from "../common/Button";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import { userLoginSuccess } from "../context/Action";
import { login } from "../api/usersApi";
import Loading from "../components/Loading";
import { ErrorMessage } from "../components/styledComponent/formInputs";

const Login = () => {
  const { dispatch } = useContext(Context);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    let emailError = "";
    let passwordError = "";

    if (password.length < 5) {
      passwordError = "password is required, minimum 5 characters";
    }
    if (!email) {
      emailError = "email is required, minimum 2 characters";
    }
    if (!email.includes("@")) {
      emailError = "email is invalid";
    }

    if (passwordError || emailError) {
      setInputError((curr) => {
        return {
          ...curr,
          password: passwordError,
          email: emailError,
        };
      });
      return false;
    }
    return true;
  };

  const handleLogin = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        const data = {
          email,
          password,
        };
        const res = await login(data);

        setLoading(false);
        dispatch(userLoginSuccess(res.data));
        toast.success("Login successfully");
        navigate("/dashboard");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <div className="login">
      <div className="login__form">
        <div>
          <div className="login__form__input">
            <input
              type="text"
              placeholder="Email address"
              // error={inputError.email ? true : false}
              onChange={(e) => setEmail(e.target.value)}
            />

            <i className="ti-email"></i>
          </div>
          <ErrorMessage>{inputError?.email}</ErrorMessage>
        </div>

        <div>
          <div className="login__form__input">
            <input
              type="password"
              placeholder="Password"
              // error={inputError.password ? true : false}
              onChange={(e) => setPassword(e.target.value)}
            />

            <i className="ti-lock"></i>
          </div>
          <ErrorMessage>{inputError?.password}</ErrorMessage>
        </div>

        <div className="login__form__forgot">
          <Link to="/forgot-password">
            <span>Forgot Password?</span>
          </Link>
        </div>

        <Button type="btnMainAlt" width="100%" onClick={handleLogin}>
          {loading ? (
            <Loading button={true} />
          ) : (
            <>
              Submit
              <i className="ti-arrow-right"></i>
            </>
          )}
        </Button>
      </div>

      {/* <p className="login__dont">
        Don't have an account? <Link to="/signup">Sign up</Link>
      </p> */}
    </div>
  );
};

export default Login;
