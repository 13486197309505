import { useContext, useEffect, useState } from "react";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { fetchCareer } from "../api/dashboardApi";
import Button from "../common/Button";

const CareerItem = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [career, setCareer] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    const handleFetchCareer = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchCareer(user?.accessToken, id);

          setCareer(res.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchCareer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, user?.accessToken]);

  return (
    <div className="careerItem">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="careerItem__main">
            <h2>Career Application Item</h2>

            <div className="careerItem__main__total" style={{ marginTop: 30 }}>
              {/* <div className="careerItem__main__total__part">
                <b>Date</b>
                {career?.createdAt?.slice(0, 10)}
              </div> */}
              <div className="careerItem__main__total__part">
                <b>Name</b>
                {career?.firstName} {career?.lastName}
              </div>
              <div className="careerItem__main__total__part">
                <b>Email</b>
                {career?.email}
              </div>
              <div className="careerItem__main__total__part">
                <b>Phone</b>
                {career?.phone}
              </div>
              <div className="careerItem__main__total__part">
                <b>Applied Position</b>
                {career?.position}
              </div>
              <div className="careerItem__main__total__part">
                <b>City</b>
                {career?.city}
              </div>
              <div className="careerItem__main__total__part">
                <b>State</b>
                {career?.state}
              </div>
              <div className="careerItem__main__total__part">
                <b>Country</b>
                {career?.country}
              </div>
              <div className="careerItem__main__total__part">
                <b>Current Job Title</b>
                {career?.currentTitle}
              </div>
              <div className="careerItem__main__total__part">
                <b>Expected Salary</b>
                {career?.salary}
              </div>
              <div className="careerItem__main__total__part">
                <b>Notice Period</b>
                {career?.noticePeriod}
              </div>
              <div className="careerItem__main__total__part">
                <b>LinkedIn</b>
                {career?.linkedin}
              </div>
              <div className="careerItem__main__total__part">
                <b>Portfolio Site</b>
                {career?.portfolioSite}
              </div>
              <div className="careerItem__main__total__part">
                <b>Twitter</b>
                {career?.twitter}
              </div>
            </div>

            <div className="careerItem__main__btn">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={career?.resume}
              >
                <Button height="40px">View Resume</Button>
              </a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CareerItem;
