import { toast } from "react-toastify";
import { forgetPassword } from "../api/usersApi";
import Button from "../common/Button";
// import { Link } from "react-router-dom";
import { useState } from "react";
import Loading from "../components/Loading";
import { ErrorMessage } from "../components/styledComponent/formInputs";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);

  const validate = () => {
    let emailError = "";

    if (!email) {
      emailError = "email is required";
    }
    if (!email.includes("@")) {
      emailError = "email is invalid";
    }

    if (emailError) {
      setInputError((curr) => {
        return {
          ...curr,
          email: emailError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSend = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        const data = {
          email,
        };

        await forgetPassword(data);

        setLoading(false);
        setStep(2);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };
  return (
    <div className="login">
      {step === 1 && (
        <>
          <h1 className="login__title">Forgot Password</h1>
          <p className="login__subtitle">
            {" "}
            Please Enter your Email Address Below
          </p>

          <div className="login__form">
            <div>
              <div className="login__form__input">
                <input
                  type="text"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <i className="ti-email"></i>
              </div>
              <ErrorMessage>{inputError?.email}</ErrorMessage>
            </div>

            <div style={{ marginTop: 20 }}>
              <Button type="btnMainAlt" width="100%" onClick={handleSend}>
                {loading ? (
                  <Loading button={true} />
                ) : (
                  <>
                    Submit
                    <i className="ti-arrow-right"></i>
                  </>
                )}
              </Button>
            </div>
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div className="loadingCon" style={{ height: 70 }}>
            <i className="ti-email suc" style={{ fontSize: 50 }}></i>
          </div>
          <h2 className="login__title">Email sent successfully</h2>
          <p className="login__subtitle">
            An email has been sent to your inbox, follow the instructions to
            Reset password and login.
          </p>
        </>
      )}
    </div>
  );
};

export default ForgetPassword;
