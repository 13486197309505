import { Link } from "react-router-dom";
import Button from "../common/Button";
import { useContext, useEffect, useState } from "react";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import { fetchBlogs } from "../api/dashboardApi";
import ReactPaginate from "react-paginate";
import Loading from "../components/Loading";
import BlogItem from "../components/BlogItem";

const Blog = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const handleFetchBlogs = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchBlogs(page, user?.accessToken);

          setBlogs(res.data?.blogs);
          setPageCount(res.data.totalPages);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, page, refresh]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  return (
    <div className="blog">
      <div className="blog__top">
        <Link to={"/dashboard/blogs/add"}>
          <Button height="40px">
            ADD Blog <i className="ti-plus"></i>
          </Button>
        </Link>
      </div>

      <div className="blog__main">
        <h2>Blogs</h2>

        {loading ? (
          <Loading />
        ) : (
          <div className="blog__main__list">
            {blogs?.map((blog) => {
              return (
                <BlogItem
                  key={blog?.id}
                  blog={blog}
                  setRefresh={setRefresh}
                  user={user}
                />
              );
            })}
          </div>
        )}

        {pageCount > 1 && (
          <div style={{ marginTop: 40 }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              breakLabel="..."
              //   renderOnZeroPageCount={null}
              onPageChange={handlePageClick}
              // pageRangeDisplayed={itemsPerPage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Blog;
