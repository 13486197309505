import DrawerDB from "./DrawerDB";
import { useContext, useEffect, useState } from "react";
import { Context } from "../context/MainContext";
import { useNavigate, Link } from "react-router-dom";
import ReactModal from "react-modal";
import { userLogOut } from "../context/Action";
import profile from "../assets/images/svg/Profile.svg";
import NotificationModal from "./NotificationModal";

const TopBar = () => {
  const { user, dispatch } = useContext(Context);
  const navigate = useNavigate();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const handleLogout = () => {
    sessionStorage.removeItem("lilyUser");
    dispatch(userLogOut());
    navigate("/login");
  };

  return (
    <nav className="topbar">
      <ReactModal
        className="modalMain"
        overlayClassName="modal-overlayUser"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Cart Modal"
      >
        <div className="topbar__modal">
          <div className="topbar__modal__img">
            <img src={user?.image ? user?.image : profile} alt="" />
          </div>

          <h3>
            {user?.firstName} {user?.lastName}
          </h3>
          <p>{user?.email}</p>

          <ul
            className="topbar__modal__list"
            onClick={() => setModalIsOpen(false)}
          >
            <Link to="/dashboard/settings">
              <li>
                <i className="ti-user"></i> Profile
              </li>
            </Link>
            <li className="last" onClick={handleLogout}>
              <i className="ti-power-off"></i> Logout
            </li>
          </ul>
        </div>
      </ReactModal>

      <DrawerDB />

      <div className="topbar__left">
        <div className="topbar__left__input">
          <input type="text" placeholder="Search..." />
          <i className="ti-search"></i>
        </div>
      </div>

      <div className="topbar__info">
        {/* <div className="topbar__info__noti">
          <i className="ti-bell"></i>
          <span></span>
        </div> */}
        {/* <NotificationModal /> */}
        {/* <div className="topbar__info__noti">
          <i className="fa fa-envelope-o"></i>
          <span></span>
        </div> */}

        <div
          className="topbar__info__user"
          onClick={() => setModalIsOpen((curr) => !curr)}
        >
          <p>
            {user?.firstName} {user?.lastName}{" "}
            <i className="feather ft-chevron-down"></i>
          </p>
          <div className="topbar__info__user__img">
            <img src={user?.image ? user?.image : profile} alt="" />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default TopBar;
