import { useContext, useEffect, useState } from "react";
import Loading from "../components/Loading";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import Button from "../common/Button";
import TableMenu from "../components/TableMenu";
import { fetchStaffs } from "../api/dashboardApi";
// import { fetchBookings, fetchBookingsBySearch } from "../api/adminApi";

const Staff = ({ type }) => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
    if (searchQuery) {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    if (searchQuery && user?.id) {
      setLoading(true);
      try {
        // const res = await fetchBookingsBySearch(
        //   searchQuery,
        //   user?.accessToken,
        //   page
        // );

        // setstaffs(res.data?.books);
        // setPageCount(res.data.totalPages);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    const handleFetchUsers = async () => {
      if (user?.id && searchQuery === "") {
        setLoading(true);
        try {
          const res = await fetchStaffs(
            page,
            user?.accessToken,
            type.toLowerCase()
          );

          setStaffs(res.data?.staffs);
          setPageCount(res.data.totalPages);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, page, user?.accessToken, refresh, type]);

  return (
    <div className="staff">
      <div className="staff__btn">
        <Link to={"/dashboard/add-" + type?.toLowerCase()}>
          <Button height="40px">
            ADD {type} <i className="ti-plus"></i>
          </Button>
        </Link>
      </div>
      <div className="staff__main">
        <div className="dashboard__users__top">
          <h3 style={{ textTransform: "uppercase" }}>{type}</h3>
          <div className="topbar__left__input">
            <input
              placeholder="Search by Name"
              type="text"
              onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
            <i className="ti-search"></i>
          </div>
        </div>
        <div className="dashboard__users__table">
          <div
            className="dashboard__users__table__body"
            style={{ minWidth: 1000 }}
          >
            <div className="dashboard__users__table__row head">
              <div
                style={{ width: "30px" }}
                className="dashboard__users__table__row__head"
              >
                s/n
              </div>
              <div
                style={{ width: "150px" }}
                className="dashboard__users__table__row__head"
              >
                Title
              </div>
              <div
                style={{ width: "120px" }}
                className="dashboard__users__table__row__head"
              >
                First Name
              </div>

              <div
                style={{ width: "120px" }}
                className="dashboard__users__table__row__head"
              >
                Last Name
              </div>
              <div
                style={{ width: "200px" }}
                className="dashboard__users__table__row__head"
              >
                Email
              </div>
              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Role
              </div>
              <div
                style={{ width: "150px" }}
                className="dashboard__users__table__row__head"
              >
                Mobile Number
              </div>
              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Gender
              </div>
              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Birth Date
              </div>
              {/* <div
                style={{ width: "150px" }}
                className="dashboard__users__table__row__head"
              >
                Marital Status
              </div> */}
              {/* <div
                style={{ width: "300px" }}
                className="dashboard__users__table__row__head"
              >
                Address
              </div> */}
              <div
                style={{ width: "50px" }}
                className="dashboard__users__table__row__head"
              ></div>
            </div>

            {loading ? (
              <Loading />
            ) : (
              staffs?.map((staff, i) => {
                return (
                  <div
                    key={staff?._id}
                    className="dashboard__users__table__row"
                  >
                    <div
                      style={{ width: "30px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {(page - 1) * 10 + (i + 1)}
                    </div>
                    <div
                      style={{ width: "150px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.title}
                    </div>
                    <div
                      style={{ width: "120px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.firstName}
                    </div>
                    <div
                      style={{ width: "120px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.lastName}
                    </div>
                    <div
                      style={{ width: "200px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {/* {staff?.description?.length > 20
                        ? `${staff?.description?.slice(0, 20)}...`
                        : staff?.description} */}
                      {staff?.email}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.role}
                    </div>
                    <div
                      style={{ width: "150px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.phone}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.gender}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.dob}
                    </div>
                    {/* <div
                      style={{ width: "150px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.maritalStatus}
                    </div> */}
                    {/* <div
                      style={{ width: "300px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.address}
                    </div> */}
                    <div
                      style={{ width: "50px" }}
                      className="dashboard__users__table__row__data"
                    >
                      <TableMenu id={staff?.id} setRefresh={setRefresh} />
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {pageCount > 1 && (
          <div style={{ marginTop: 40 }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              breakLabel="..."
              //   renderOnZeroPageCount={null}
              onPageChange={handlePageClick}
              // pageRangeDisplayed={itemsPerPage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Staff;
