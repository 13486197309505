import { useContext, useEffect, useState } from "react";
import { Context } from "../context/MainContext";
import { fetchImagesByPageName } from "../api/dashboardApi";
import { toast } from "react-toastify";
import ImageItem from "../components/ImageItem";
import Loading from "../components/Loading";

const ImagesHome = ({ type }) => {
  const { user } = useContext(Context);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const handleImages = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchImagesByPageName(type, user?.accessToken);

          setImages(res.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, refresh, type]);

  return (
    <div className="imagesHome">
      {loading ? (
        <Loading />
      ) : (
        <>
          <h1>{type} Page</h1>
          <div className="imagesHome__main">
            {images?.map((item) => {
              return (
                <ImageItem item={item} setRefresh={setRefresh} user={user} />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ImagesHome;
