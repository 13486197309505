import React from "react";

const Button = ({ children, onClick, type, width, height }) => {
  return (
    <button
      style={{
        width: width ? width : "180px",
        height: height ? height : "50px",
      }}
      className={type ? type : "btnMainAlt"}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
