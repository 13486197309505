import React, { useContext, useEffect, useState } from "react";
import Loading from "../components/Loading";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import { fetchServices } from "../api/dashboardApi";
import ServiceItem from "../components/ServiceItem";

const Service = () => {
  const { user } = useContext(Context);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const handleFetchServices = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchServices(user?.accessToken);

          setServices(res.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, refresh]);

  return (
    <div className="service">
      <div className="service__main">
        <h3>Services</h3>

        {loading ? (
          <Loading />
        ) : (
          <div className="service__main__list">
            {services.map((item) => {
              return (
                <ServiceItem
                  item={item}
                  key={item?.id}
                  user={user}
                  setRefresh={setRefresh}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Service;
