import { Outlet, useNavigate } from "react-router-dom";
import logo from "../assets/images/svg/logo.svg";
import Button from "../common/Button";
import { useContext, useEffect } from "react";
import { Context } from "../context/MainContext";

const AuthLayout = () => {
  const { user } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.id) {
      navigate("/dashboard");
    }
  }, [user, navigate]);

  return (
    <div className="authLayout">
      <div className="authLayout__left">
        <img src={logo} alt="Logo" />
        <p>
          A business support company you can trust <br />
          We're Lilysolutions. We help drive change with technology.
        </p>

        <Button height="40px" type="btnMain">
          Learn More
        </Button>
      </div>

      <div className="authLayout__right">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
