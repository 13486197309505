import React, { useEffect, useState } from "react";
import { Input } from "./styledComponent/formInputs";
import Button from "../common/Button";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { updatePrice } from "../api/dashboardApi";

const PriceItem = ({ price, user }) => {
  const [edit, setEdit] = useState(false);
  const [amount, setAmount] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let data = { amount };

      await updatePrice(price?.id, data, user?.accessToken);

      setLoading(false);
      toast.success("Saved successfully");
      setEdit(false);
    } catch (err) {
      setLoading(false);
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      toast.error(message);
    }
  };

  useEffect(() => {
    setAmount(price?.amount);
  }, [price]);

  return (
    <div key={price?.id} className="prices__main__list__item">
      <h4>{price?.name}</h4>

      {edit ? (
        <Input
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          place="Amount"
          height="50px"
          style={{ fontSize: 25 }}
        />
      ) : (
        <p>{amount}</p>
      )}

      <i
        onClick={() => setEdit((curr) => !curr)}
        className="feather ft-edit"
      ></i>

      {edit && (
        <div style={{ marginTop: 10 }}>
          <Button
            height={30}
            width="100%"
            onClick={handleSubmit}
            className="btn"
          >
            {loading ? <Loading button={true} /> : "Save"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PriceItem;
