import React, { useEffect, useRef, useState } from "react";
import Button from "../common/Button";
import { toast } from "react-toastify";
import Loading from "./Loading";
import { updateImage } from "../api/dashboardApi";

const ImageItem = ({ item, setRefresh, user }) => {
  const [edit, setEdit] = useState(false);
  const [imageChange, setImageChange] = useState(false);
  const [mainItem, setMainItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setuploadedFile] = useState("");

  const videoRef = useRef();

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file?.size > 5297150) {
      toast.warning("File too big, max size 5mb");
    } else {
      setImageChange(true);
      setuploadedFile(file);
      const newItem = { ...mainItem, url: URL.createObjectURL(file) };
      setMainItem(newItem);

      if (mainItem.name === "Hero Video") {
        videoRef.current?.load();
      }
    }
  };

  const handleSubmit = async () => {
    if (imageChange) {
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("uploadedFile", uploadedFile);
        formData.append("url", item?.url);

        await updateImage(mainItem.id, formData, user?.accessToken);

        setLoading(false);
        toast.success("Saved successfully");
        setImageChange(false);
        setEdit(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    setMainItem(item);
  }, [item]);

  return (
    <div key={mainItem.id} className="imagesHome__main__item">
      <div className="imagesHome__main__item__media">
        {mainItem.name === "Hero Video" ? (
          <video autoPlay loop muted ref={videoRef}>
            <source src={mainItem.url} type="video/mp4" />
          </video>
        ) : (
          <img src={mainItem.url} alt="" />
        )}

        <div className="imagesHome__main__item__media__edit">
          <i
            onClick={() => setEdit((curr) => !curr)}
            className="feather ft-edit"
          ></i>
        </div>
      </div>
      <div className="imagesHome__main__item__bottom">
        <p>{mainItem?.name}</p>
        {imageChange && (
          <Button width="100px" height="30px" onClick={handleSubmit}>
            {loading ? <Loading button={true} /> : "Save"}
          </Button>
        )}
      </div>
      {edit && (
        <input
          type="file"
          onChange={(e) => handleFileUpload(e)}
          accept={
            mainItem?.name === "Hero Video"
              ? "video/*"
              : "image/png, image/gif, image/jpeg, image/jpg"
          }
        />
      )}
    </div>
  );
};

export default ImageItem;
