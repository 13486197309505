import { useContext, useEffect, useState } from "react";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import { getAllPrices } from "../api/dashboardApi";
import Loading from "../components/Loading";
import PriceItem from "../components/PriceItem";

const Prices = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [prices, setPrices] = useState([]);

  useEffect(() => {
    const handleImages = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await getAllPrices();

          setPrices(res.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <div className="prices">
      <div className="prices__main">
        <h1>Prices</h1>

        {loading ? (
          <Loading />
        ) : (
          <div className="prices__main__list">
            {prices.map((price) => {
              return <PriceItem key={price?.id} price={price} user={user} />;
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Prices;
