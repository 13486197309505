import React, { useContext, useEffect, useState } from "react";
import Conversation from "../components/Conversation";
import { toast } from "react-toastify";
import { fetchChatMessage, sendChat } from "../api/dashboardApi";
import { Context } from "../context/MainContext";
import { Tooltip } from "react-tooltip";
import moment from "moment";
import { useSocketContext } from "../context/SocketContext";

const Chat = () => {
  const { user } = useContext(Context);
  const [currChat, setCurrChat] = useState({});
  const [text, setText] = useState("");
  const [conversationId, setConversationId] = useState("");
  const [chats, setChats] = useState([]);
  const [sentText, setsentText] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshAlt, setRefreshAlt] = useState(false);
  const { socket } = useSocketContext();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (text?.length > 0) {
      setLoading(true);
      try {
        const data = {
          userId: user?.id,
          otherUserId: currChat?.id,
          text,
        };

        await sendChat(user?.accessToken, data);

        //   setConversation(res.data);
        //   setCurrChat(res.data?.[0]);
        setsentText(text);
        setText("");
        setRefresh((curr) => !curr);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    const handleFetchChat = async () => {
      if (user?.id && conversationId) {
        setLoadingChat(true);
        try {
          const res = await fetchChatMessage(
            user?.accessToken,
            user?.id,
            conversationId
          );

          setChats(res.data);
          setLoadingChat(false);
          setRefreshAlt((curr) => !curr);
        } catch (err) {
          setLoadingChat(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
      if (user?.id && !conversationId && sentText.length > 0) {
        setChats([{ text: sentText, senderId: user?.id }, ...chats]);
      }
    };

    handleFetchChat();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, refresh, conversationId]);

  useEffect(() => {
    socket?.on("newChat", (newChat) => {
      // newMessage.shouldShake = true;
      // const sound = new Audio(notificationSound);
      // sound.play();
      // setMessages([...messages, newMessage]);
      setRefresh((curr) => !curr);
    });

    return () => socket?.off("newMessage");
  }, [socket]);

  return (
    <div className="chat">
      <Conversation
        setCurrChat={setCurrChat}
        currChat={currChat}
        setConversationId={setConversationId}
        refresh={refresh}
        setChats={setChats}
        refreshAlt={refreshAlt}
      />

      <div className="chat__main">
        <div className="chat__main__top">
          {currChat?.firstName} {currChat?.lastName}
        </div>

        {currChat?.id && (
          <>
            <div className="chat__main__message">
              {chats?.map((chat, i) => {
                return (
                  <div
                    key={i}
                    className={
                      chat?.senderId === user?.id
                        ? "chat__main__message__item"
                        : "chat__main__message__item alt"
                    }
                  >
                    <div
                      className={
                        chat?.senderId === user?.id
                          ? "chat__main__message__item__text"
                          : "chat__main__message__item__text alt"
                      }
                    >
                      {chat?.text}
                      <span
                        className={
                          chat?.senderId === user?.id
                            ? "chat__main__message__item__text__ind"
                            : "chat__main__message__item__text__ind alt"
                        }
                      ></span>
                    </div>

                    <Tooltip id={"tooltip" + i} />
                    <div
                      data-tooltip-id={"tooltip" + i}
                      className="chat__main__message__item__date"
                      data-tooltip-content={moment(chat?.createdAt).calendar(
                        null,
                        {
                          sameDay: "[Today]",
                          nextDay: "ddd MMMM DD YYYY",
                          nextWeek: "ddd MMMM DD YYYY",
                          lastDay: "[Yesterday]",
                          lastWeek: "ddd MMMM DD YYYY",
                          sameElse: "ddd MMMM DD YYYY",
                        }
                      )}
                    >
                      {/* .format("ddd MMMM DD YYYY") */}
                      {chat?.createdAt &&
                        moment(chat?.createdAt).format("hh:mm a")}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="chat__main__input">
              <form onSubmit={handleSubmit}>
                <input
                  type="text"
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Send a message"
                />
                <i className="fa fa-send"></i>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Chat;
