import React, { useEffect, useState } from "react";
import Button from "../common/Button";
import { Link, useParams } from "react-router-dom";
import Loading from "../components/Loading";
import { toast } from "react-toastify";
import { verifyEmail } from "../api/usersApi";

const VerifyMail = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const handleVerify = async () => {
      setLoading(true);
      try {
        await verifyEmail(id);

        setLoading(false);
        setSuccess(true);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    };

    handleVerify();
  }, [id]);

  return (
    <div className="login">
      <h1 className="login__title" style={{ marginBottom: 20 }}>
        Email Verification
      </h1>

      {loading ? (
        <Loading />
      ) : success ? (
        <>
          <p className="login__subtitle suc">
            Verification Successful Proceed to Login
          </p>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
          >
            <Link to="/login">
              <Button type="btnMainAlt" width="200px">
                Login
                <i className="ti-arrow-right"></i>
              </Button>
            </Link>
          </div>
        </>
      ) : (
        <p className="login__subtitle fail">Verification failed</p>
      )}
    </div>
  );
};

export default VerifyMail;
