import { useContext, useState } from "react";
import {
  ErrorMessage,
  Input,
  Select,
  Textarea,
} from "../components/styledComponent/formInputs";
import Button from "../common/Button";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { addStaff } from "../api/dashboardApi";
import { Context } from "../context/MainContext";

const AddStaff = ({ type }) => {
  const { user } = useContext(Context);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState(type.toLowerCase());
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [address, setAddress] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";

    if (!firstName) {
      firstNameError = "first name is required";
    }
    if (!lastName) {
      lastNameError = "last name is required";
    }
    if (!email.includes("@")) {
      emailError = "email is invalid";
    }
    if (!email) {
      emailError = "email is required";
    }

    if (emailError || lastNameError || firstNameError) {
      setInputError((curr) => {
        return {
          ...curr,
          email: emailError,
          lastName: lastNameError,
          firstName: firstNameError,
        };
      });
      return false;
    }
    return true;
  };

  const handleAddStaff = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let data = {
          email,
          firstName,
          lastName,
          title,
          phone,
          gender,
          role,
          dob,
          maritalStatus,
          address,
        };

        await addStaff(data, user?.accessToken);

        setLoading(false);
        toast.success("User Added successfully");
        setFirstName("");
        setLastName("");
        setEmail("");
        setAddress("");
        setRole("staff");
        setTitle("");
        setPhone("");
        setGender("");
        setDob("");
        setMaritalStatus("");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <div className="profile">
      <div className="profile__main">
        <h2
          className="profile__main__title"
          style={{ textTransform: "uppercase" }}
        >
          {" "}
          ADD {type}
        </h2>

        <div className="profile__main__con">
          <div
            className="profile__main__info"
            style={{ width: "100%", paddingLeft: 30 }}
          >
            <div className="profile__main__info__item">
              <b>First Name</b>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <Input
                  width="300px"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <ErrorMessage>{inputError?.firstName}</ErrorMessage>
              </div>
            </div>
            <div className="profile__main__info__item">
              <b>Last Name</b>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <Input
                  width="300px"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <ErrorMessage>{inputError?.lastName}</ErrorMessage>
              </div>
            </div>
            <div className="profile__main__info__item">
              <b>Title</b>

              <Input
                width="300px"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="profile__main__info__item">
              <b>Email</b>

              <div style={{ display: "flex", flexDirection: "column" }}>
                <Input
                  width="300px"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <ErrorMessage>{inputError?.email}</ErrorMessage>
              </div>
            </div>
            <div className="profile__main__info__item">
              <b>Mobile Number</b>

              <Input
                width="300px"
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="profile__main__info__item">
              <b>Gender</b>

              <Select
                width="300px"
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="--select gender--"></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
            </div>
            <div className="profile__main__info__item">
              <b>Birth Date</b>

              <Input
                type="date"
                width="300px"
                value={dob}
                onChange={(e) => setDob(e.target.value)}
              />
            </div>
            <div className="profile__main__info__item">
              <b>Marital Status</b>

              <Select
                width="300px"
                value={maritalStatus}
                onChange={(e) => setMaritalStatus(e.target.value)}
              >
                <option value="--select marital status--"></option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
              </Select>
            </div>

            <div className="profile__main__info__item">
              <b>Role</b>

              <Select
                width="300px"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                {/* <option value="staff">Staff</option> */}
                <option value={type.toLowerCase()}>{type}</option>
              </Select>
            </div>
            <div
              //   style={{ margin: 30, marginTop: "0px" }}
              className="profile__main__info__item"
            >
              <b>Address</b>

              <Textarea
                width="300px"
                value={address}
                rows={3}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="profile__main__btn">
          <Button height="40px" onClick={handleAddStaff}>
            {loading ? <Loading button={true} /> : "Add"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddStaff;
