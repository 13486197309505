import React, { useContext, useEffect, useState } from "react";
import { Context } from "../context/MainContext";
import { fetchTestimonials } from "../api/dashboardApi";
import { toast } from "react-toastify";
import TestimonialsItem from "../components/TestimonialsItem";
import Loading from "../components/Loading";

const Testimonials = () => {
  const { user } = useContext(Context);
  const [testimoials, setTestimoials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const handleFetchBlogs = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchTestimonials(user?.accessToken);

          setTestimoials(res.data);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchBlogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, refresh]);

  return (
    <div className="testimonials">
      <div className="testimonials__main">
        <h3>Testimonials</h3>

        {loading ? (
          <Loading />
        ) : (
          <div className="testimonials__main__list">
            {testimoials.map((item) => {
              return (
                <TestimonialsItem
                  item={item}
                  key={item?.id}
                  user={user}
                  setRefresh={setRefresh}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default Testimonials;
