import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL + "/api";

// export const sendMessage = (data) => {
//   return axios.post(`${apiURL}/message/add`, data);
// };

export const login = (data) => {
  return axios.post(`${apiURL}/auth/signin`, data);
};

export const signup = (data) => {
  return axios.post(`${apiURL}/auth/signup`, data);
};

export const verifyEmail = (token) => {
  return axios.put(`${apiURL}/auth/email-verify/${token}`);
};

export const forgetPassword = (data) => {
  return axios.post(`${apiURL}/auth/forget-password`, data);
};

export const resetPasswordFp = (data) => {
  return axios.put(`${apiURL}/auth/reset-password-fp`, data);
};

export const addCareer = (data) => {
  return axios.post(`${apiURL}/career/add`, data);
};

export const addTraining = (data) => {
  return axios.post(`${apiURL}/training/add`, data);
};
