import React, { useEffect, useState } from "react";
import { Input, Textarea } from "./styledComponent/formInputs";
import { toast } from "react-toastify";
import { updateTestimonial } from "../api/dashboardApi";
import Button from "../common/Button";
import Loading from "./Loading";

const TestimonialsItem = ({ item, user, setRefresh }) => {
  const [edit, setEdit] = useState(false);
  const [text, setText] = useState("");
  const [editText, setEditText] = useState(false);
  const [name, setName] = useState("");
  const [editName, setEditName] = useState(false);
  const [title, setTitle] = useState("");
  const [editTitle, setEditTitle] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (item?.text) {
      setText(item?.text);
      setEditText(true);
    }

    if (item?.name) {
      setName(item?.name);
      setEditName(true);
    }
    if (item?.title) {
      setTitle(item?.title);
      setEditTitle(true);
    }
  }, [item]);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      let data = { text, title, name };

      await updateTestimonial(item?.id, data, user?.accessToken);

      setLoading(false);
      toast.success("Saved successfully");
      setEdit(false);
      setRefresh((curr) => !curr);
    } catch (err) {
      setLoading(false);
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      toast.error(message);
    }
  };

  return (
    <div className="testimonials__main__list__item">
      {!edit ? (
        <>
          <p>{item?.text}</p>
          <h5>{item?.name}</h5>
          <h6>{item?.title}</h6>
        </>
      ) : (
        <div className="testimonials__main__list__item__form">
          {editText && (
            <Textarea
              type="text"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Text"
              rows={3}
            ></Textarea>
          )}
          {editName && (
            <Input
              style={{ marginBottom: 10 }}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
            />
          )}

          {editTitle && (
            <Input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
            />
          )}
        </div>
      )}

      <div className="testimonials__main__list__item__action">
        <i
          className="feather ft-edit"
          onClick={() => setEdit((curr) => !curr)}
        ></i>

        {edit && (
          <Button width="100px" height="40px" onClick={handleSubmit}>
            {loading ? <Loading button={true} /> : "Save"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TestimonialsItem;
