export const userLoginSuccess = (user) => ({
  type: "USER_LOGIN_SUCCESS",
  payload: user,
});

export const userDetailsUpdateSuccess = (user) => ({
  type: "USER_UPDATE_SUCCESS",
  payload: user,
});

export const userLogOut = () => ({
  type: "USER_LOGOUT",
});
