import { useContext, useState } from "react";
import {
  ErrorMessage,
  Input,
  Label,
  Textarea,
} from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import Button from "../common/Button";
import Loading from "../components/Loading";
import { addBlog } from "../api/dashboardApi";
import { Context } from "../context/MainContext";
import { Link } from "react-router-dom";

const AddBlog = () => {
  const { user } = useContext(Context);
  const [inputError, setInputError] = useState({});
  const [fileName, setfileName] = useState("");
  const [title, setTitle] = useState("");
  const [tag, setTag] = useState("");
  const [youtube, setYoutube] = useState("");
  const [summary, setSummary] = useState("");
  const [content, setContent] = useState("");
  const [uploadedFile, setuploadedFile] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file?.size > 329715) {
      toast.warning("File too big, max size 300kb");
    } else {
      setfileName(file?.name);
      setuploadedFile(file);
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };

  const validate = () => {
    let titleError = "";
    let tagError = "";
    // let youtubeError = "";
    let summaryError = "";
    let contentError = "";
    let uploadedFileError = "";

    if (!title) {
      titleError = "title is required";
    }
    if (!tag) {
      tagError = "tag is required";
    }
    // if (!youtube) {
    //   youtubeError = "youtube link is required";
    // }
    if (!summary) {
      summaryError = "summary is required";
    }
    if (!content) {
      contentError = "content is required";
    }
    if (!uploadedFile) {
      uploadedFileError = "cover image is required";
    }

    if (
      titleError ||
      tagError ||
      // youtubeError ||
      uploadedFileError ||
      summaryError ||
      contentError
    ) {
      setInputError((curr) => {
        return {
          ...curr,
          title: titleError,
          tag: tagError,
          summary: summaryError,
          content: contentError,
          uploadedFile: uploadedFileError,
          // youtube: youtubeError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("title", title);
        formData.append("tag", tag);
        formData.append("youtube", youtube);
        formData.append("summary", summary);
        formData.append("content", content);
        formData.append("uploadedFile", uploadedFile);

        await addBlog(formData, user?.accessToken);

        setLoading(false);
        toast.success("Blog added successfully");
        // setStep(2);
        setTitle("");
        setContent("");
        setSummary("");
        setTag("");
        setYoutube("");
        setuploadedFile("");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <div className="addBlog">
      <div className="addBlog__main">
        <h1 className="addBlog__main__title">
          Add Blog
          <Link to="/dashboard/blogs">
            <i className="ti-arrow-circle-left"></i>
          </Link>
        </h1>
        <div className="addBlog__main__form">
          <div className="addBlog__main__form__item">
            <Label htmlFor="title">
              Title <span>*</span>
            </Label>

            <Input
              width="300px"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <ErrorMessage>{inputError?.title}</ErrorMessage>
          </div>

          <div className="addBlog__main__form__item">
            <Label htmlFor="tag">
              Tag <span>*</span>
            </Label>
            <Input
              id="tag"
              width="300px"
              value={tag}
              onChange={(e) => setTag(e.target.value)}
            />
            <ErrorMessage>{inputError?.tag}</ErrorMessage>
          </div>
          <div className="addBlog__main__form__item">
            <Label htmlFor="youtube">Youtube link</Label>

            <Input
              width="300px"
              id="youtube"
              value={youtube}
              onChange={(e) => setYoutube(e.target.value)}
            />
            <ErrorMessage>{inputError?.youtube}</ErrorMessage>
          </div>
          <div className="addBlog__main__form__item">
            <Label htmlFor="summary">
              Summary <span>*</span>
            </Label>

            <Textarea
              width="300px"
              id="summary"
              value={summary}
              onChange={(e) => setSummary(e.target.value)}
              rows={3}
              style={{ width: "100%" }}
            />
            <ErrorMessage>{inputError?.summary}</ErrorMessage>
          </div>
          <div
            className="careerApp__main__input"
            style={{ marginBottom: "20px" }}
          >
            <Label htmlFor="cover">
              Cover Image <span>*</span>
            </Label>
            <br />
            <input
              style={{ display: "none" }}
              id="cover"
              type="file"
              onChange={(e) => handleFileUpload(e)}
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <label className="careerApp__main__fileLabel" htmlFor="cover">
              {!fileName ? "Choose File" : fileName}
              <span>Max file size: 300kb</span>
            </label>
            <ErrorMessage>{inputError?.uploadedFile}</ErrorMessage>
          </div>

          <div className="addBlog__main__form__item">
            <Label htmlFor="content">
              Content <span>*</span>
            </Label>

            <ReactQuill
              id="content"
              theme="snow"
              modules={modules}
              value={content}
              onChange={setContent}
              //    value={value} onChange={setValue}
              style={{ height: 500 }}
            />
            <ErrorMessage>{inputError?.content}</ErrorMessage>
          </div>
        </div>

        <div className="addBlog__main__form__btn">
          <Button onClick={handleSubmit}>
            {" "}
            {loading ? <Loading button={true} /> : "Add"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddBlog;
