import { useState } from "react";
import logo from "../assets/images/svg/logo.svg";
import Button from "../common/Button";
import {
  ErrorMessage,
  Input,
  Label,
  Select,
} from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { addCareer } from "../api/usersApi";

const CareerApp = () => {
  const [position, setposition] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [city, setcity] = useState("");
  const [state, setstate] = useState("");
  const [country, setcountry] = useState("");
  const [currentTitle, setcurrentTitle] = useState("");
  const [salary, setsalary] = useState("");
  const [noticePeriod, setnoticePeriod] = useState("");
  const [linkedin, setlinkedin] = useState("");
  const [portfolioSite, setportfolioSite] = useState("");
  const [twitter, settwitter] = useState("");
  const [uploadedFile, setuploadedFile] = useState("");
  const [fileName, setfileName] = useState("");

  const [step, setStep] = useState(1);
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file?.size > 329715) {
      toast.warning("File too big");
    } else {
      setfileName(file?.name);
      setuploadedFile(file);
    }
  };

  const validate = () => {
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";
    let positionError = "";
    let phoneError = "";
    let cityError = "";
    let stateError = "";
    let countryError = "";
    let currentTitleError = "";
    let linkedinError = "";
    let uploadedFileError = "";

    if (!firstName) {
      firstNameError = "first name is required";
    }
    if (!lastName) {
      lastNameError = "last name is required";
    }
    if (!email) {
      emailError = "email is required";
    }
    if (!email.includes("@")) {
      emailError = "email is invalid";
    }
    if (!position) {
      positionError = "position is required";
    }
    if (!phone) {
      phoneError = "mobile is required";
    }
    if (!city) {
      cityError = "city is required";
    }
    if (!state) {
      stateError = "state is required";
    }
    if (!country) {
      countryError = "country is required";
    }
    if (!linkedin) {
      linkedinError = "linkedin is required";
    }
    if (!currentTitle) {
      currentTitleError = "current job title is required";
    }
    if (!uploadedFile) {
      uploadedFileError = "resume is required";
    }

    if (
      emailError ||
      lastNameError ||
      firstNameError ||
      uploadedFileError ||
      positionError ||
      cityError ||
      stateError ||
      currentTitleError ||
      phoneError ||
      countryError ||
      linkedinError
    ) {
      setInputError((curr) => {
        return {
          ...curr,
          email: emailError,
          lastName: lastNameError,
          firstName: firstNameError,
          phone: phoneError,
          position: positionError,
          city: cityError,
          state: stateError,
          currentTitle: currentTitleError,
          country: countryError,
          linkedin: linkedinError,
          uploadedFile: uploadedFileError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("email", email);
        formData.append("position", position);
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("phone", phone);
        formData.append("city", city);
        formData.append("state", state);
        formData.append("country", country);
        formData.append("currentTitle", currentTitle);
        formData.append("salary", salary);
        formData.append("noticePeriod", noticePeriod);
        formData.append("linkedin", linkedin);
        formData.append("portfolioSite", portfolioSite);
        formData.append("twitter", twitter);
        formData.append("uploadedFile", uploadedFile);

        await addCareer(formData);

        setLoading(false);
        // toast.success("Signup successfully");
        setStep(2);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  const positions = [
    "Full Stack Developer",
    "Intern",
    "Buisness Developer",
    "Full Stack Developer Intern",
    "HR",
    "Customer Representative",
    "Graphics Designer",
    "Data Scientist",
    "Video Editor",
    "Photographer",
  ];

  return (
    <div className="careerApp">
      <div className="careerApp__header">
        <img src={logo} alt="Logo" />

        <h1>Career Application</h1>
        <p>Elevate Your Career with Lily Solutions Limited.</p>
      </div>

      {step === 1 && (
        <div className="careerApp__main">
          <p style={{ fontSize: 16 }}>
            Please complete the form below to apply for a position with us.
          </p>
          <br />
          <div className="careerApp__main__input">
            <Label htmlFor="position">
              Position <span>*</span>
            </Label>
            <Select
              id="position"
              value={position}
              onChange={(e) => setposition(e.target.value)}
            >
              <option value="">-- select position --</option>
              {positions.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
            <ErrorMessage>{inputError?.position}</ErrorMessage>
          </div>

          <h3>Basic Info</h3>
          <div className="careerApp__main__input">
            <Label htmlFor="firstName">
              First Name <span>*</span>
            </Label>
            <Input
              id="firstName"
              value={firstName}
              onChange={(e) => setfirstName(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.firstName}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="lastName">
              Last Name <span>*</span>
            </Label>
            <Input
              id="lastName"
              value={lastName}
              onChange={(e) => setlastName(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.lastName}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="email">
              Email <span>*</span>
            </Label>
            <Input
              id="email"
              value={email}
              onChange={(e) => setemail(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.email}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="mobile">
              Mobile <span>*</span>
            </Label>
            <Input
              id="mobile"
              value={phone}
              onChange={(e) => setphone(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.phone}</ErrorMessage>
          </div>

          <h3>Address Information</h3>
          <div className="careerApp__main__input">
            <Label htmlFor="city">
              City <span>*</span>
            </Label>
            <Input
              id="city"
              value={city}
              onChange={(e) => setcity(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.city}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="state">
              State/Province <span>*</span>
            </Label>
            <Input
              id="state"
              value={state}
              onChange={(e) => setstate(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.state}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="country">
              Country <span>*</span>
            </Label>
            <Input
              id="country"
              value={country}
              onChange={(e) => setcountry(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.country}</ErrorMessage>
          </div>

          <h3>Professional Details</h3>
          <div className="careerApp__main__input">
            <Label htmlFor="title">
              Current Job Title <span>*</span>
            </Label>
            <Input
              id="title"
              value={currentTitle}
              onChange={(e) => setcurrentTitle(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.currentTitle}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="noticePeriod">Notice Period</Label>
            <Input
              id="noticePeriod"
              value={noticePeriod}
              onChange={(e) => setnoticePeriod(e.target.value)}
            />
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="salary">Expected Salary</Label>
            <Input
              id="salary"
              value={salary}
              onChange={(e) => setsalary(e.target.value)}
            />
          </div>

          <h3>Social Network</h3>
          <div className="careerApp__main__input">
            <Label htmlFor="LinkedIn">
              LinkedIn <span>*</span>
            </Label>
            <Input
              id="LinkedIn"
              value={linkedin}
              onChange={(e) => setlinkedin(e.target.value)}
            />
            <ErrorMessage top="-15px">{inputError?.linkedin}</ErrorMessage>
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="site">Portfolio Site</Label>
            <Input
              id="site"
              value={portfolioSite}
              onChange={(e) => setportfolioSite(e.target.value)}
            />
          </div>
          <div className="careerApp__main__input">
            <Label htmlFor="Twitter">Twitter</Label>
            <Input
              id="Twitter"
              value={twitter}
              onChange={(e) => settwitter(e.target.value)}
            />
          </div>

          <h3>Attachment</h3>
          <div className="careerApp__main__input">
            <Label htmlFor="Resume">
              Resume <span>*</span>
            </Label>
            <br />
            <input
              style={{ display: "none" }}
              id="Resume"
              type="file"
              onChange={(e) => handleFileUpload(e)}
              accept=".pdf,.doc,.docx"
            />
            <label className="careerApp__main__fileLabel" htmlFor="Resume">
              {!fileName ? "Choose File" : fileName}
              <span>Max file size: 300kb</span>
            </label>
            <ErrorMessage>{inputError?.uploadedFile}</ErrorMessage>
          </div>

          <div className="careerApp__main__btn">
            <Button width="250px" onClick={handleSubmit}>
              {loading ? <Loading button={true} /> : "Submit Application"}
            </Button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="careerApp__main">
          <h3 style={{ textAlign: "center", fontSize: 20 }} className="suc">
            Application submitted. Thank you for applying. We will reach out to
            you soon.
          </h3>
        </div>
      )}
    </div>
  );
};

export default CareerApp;
