import React, { useContext, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import { fetchConversation, fetchUsersChat } from "../api/dashboardApi";
import Loading from "./Loading";
import { useSocketContext } from "../context/SocketContext";

const Conversation = ({
  setCurrChat,
  currChat,
  setConversationId,
  refresh,
  setChats,
  refreshAlt,
}) => {
  const { user } = useContext(Context);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [users, setUsers] = useState([]);
  // const [refreshAlt, setRefreshAlt] = useState(false);
  const { onlineUsers } = useSocketContext();

  const handleAddConv = (selctedUser) => {
    const data = {
      otherUser: selctedUser,
    };
    if (conversation?.length === 0) {
      setConversation([data]);
      setConversationId("");
      setCurrChat(selctedUser);
      setChats([]);
    } else {
      const checkConv = conversation?.filter(
        (curr) => curr?.otherUser?.id === selctedUser?.id
      );

      if (checkConv?.length > 0) {
        setCurrChat(selctedUser);
        setConversationId(checkConv?.[0]?.conversationId);
      } else {
        setConversation([data, ...conversation]);
        setCurrChat(selctedUser);
        setConversationId("");
        setChats([]);
      }
    }
    setModalIsOpen(false);
  };

  const checkOnlineStatus = (id) => {
    if (onlineUsers?.length > 0 && onlineUsers?.includes(id)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const handleFetchConversation = async () => {
      if (user?.id) {
        setLoading(true);
        try {
          const res = await fetchConversation(user?.id, user?.accessToken);

          setConversation(res.data);
          // setCurrChat(res.data?.[0]?.otherUser);
          // setConversationId(res.data?.[0]?.conversationId);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchConversation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, refresh, refreshAlt]);

  useEffect(() => {
    const handleFetchUsers = async () => {
      if (user?.id) {
        setLoadingUsers(true);
        try {
          const res = await fetchUsersChat(user?.accessToken);

          const filteredUsers = res.data?.filter(
            (curr) => user?.id !== curr?.id
          );
          setUsers(filteredUsers);
          setLoadingUsers(false);
        } catch (err) {
          setLoadingUsers(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchUsers();
  }, [user?.id, user?.accessToken]);

  return (
    <div className="conversation">
      <div className="conversation__search">
        <input type="text" placeholder="Search..." />
        {/* <i className="ti-search"></i> */}
      </div>

      <div className="conversation__list">
        {/* {loading ? (
          <Loading />
        ) : ( */}
        {conversation.map((item) => {
          return (
            <div
              key={item?.otherUser?.id}
              className={
                currChat?.id === item?.otherUser?.id
                  ? "conversation__list__item active"
                  : "conversation__list__item"
              }
              onClick={() => {
                setCurrChat(item?.otherUser);
                setConversationId(item?.conversationId);
              }}
            >
              <div className="conversation__list__item__left">
                <div className="conversation__list__item__profile">
                  {/* <img src="" alt="" /> */}
                  <span>
                    {" "}
                    {item?.otherUser?.firstName?.slice(0, 1)}
                    {item?.otherUser?.lastName?.slice(0, 1)}
                  </span>
                  {checkOnlineStatus(item?.otherUser?.id) && <div></div>}
                </div>

                <p className="conversation__list__item__name">
                  {item?.otherUser?.firstName} {item?.otherUser?.lastName}
                </p>
              </div>

              {item?.unreadMessage > 0 && (
                <div className="conversation__list__item__unread">
                  <span>{item?.unreadMessage}</span>
                </div>
              )}
            </div>
          );
        })}
        {/* )} */}

        {/* <div className="conversation__list__item">
          <div className="conversation__list__item__left">
            <div className="conversation__list__item__profile">
              <span>PJ</span>
            </div>

            <p className="conversation__list__item__name">Peter John</p>
          </div>

          
        </div> */}
      </div>

      <div className="conversation__add" onClick={() => setModalIsOpen(true)}>
        <i className="ti-plus"></i>
      </div>

      <ReactModal
        className="modalMain"
        overlayClassName="modal-overlayCenter"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="conversation Modal"
      >
        <div className="conversation__modal">
          <div className="conversation__search">
            <input type="text" placeholder="Search..." />
            {/* <i className="ti-search"></i> */}
          </div>

          <div
            className="conversation__list"
            style={{ height: 500, overflow: "auto" }}
          >
            {loadingUsers ? (
              <Loading />
            ) : (
              users?.map((item) => {
                return (
                  <div
                    key={item?.id}
                    onClick={() => handleAddConv(item)}
                    className="conversation__list__item"
                  >
                    <div className="conversation__list__item__left">
                      <div className="conversation__list__item__profile">
                        {/* <img src="" alt="" /> */}
                        <span>
                          {item?.firstName?.slice(0, 1)}
                          {item?.lastName?.slice(0, 1)}
                        </span>
                      </div>

                      <p className="conversation__list__item__name">
                        {item?.firstName} {item?.lastName}
                      </p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default Conversation;
