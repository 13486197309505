import { useContext, useEffect, useState } from "react";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import WebsiteInfo from "./WebsiteInfo";
import { Context } from "../context/MainContext";

const Settings = () => {
  const [view, setView] = useState("Profile");
  const [headerList, setHeaderList] = useState(["Profile", "Change Pasword"]);
  const { user } = useContext(Context);

  // const headerList = ["Profile", "Change Pasword",];

  useEffect(() => {
    if (user?.role === "admin") {
      setHeaderList(["Profile", "Change Pasword", "Website Info"]);
    }
  }, [user?.role]);

  return (
    <div className="settings">
      <div className="settings__header">
        <ul className="settings__header__list">
          {headerList.map((item) => {
            return (
              <li
                onClick={() => setView(item)}
                className={
                  view === item
                    ? "settings__header__list active"
                    : "settings__header__list"
                }
                key={item}
              >
                {item}
              </li>
            );
          })}
        </ul>
      </div>

      <div className="settings__main">
        {view === "Profile" && <Profile />}
        {view === "Change Pasword" && <ChangePassword />}
        {view === "Website Info" && user?.role === "admin" && <WebsiteInfo />}
      </div>
    </div>
  );
};

export default Settings;
