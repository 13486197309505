import { useState } from "react";
import { Link } from "react-router-dom";

const SideBarDropdown = ({ pathname, link, drawer, setModalIsOpen }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="sidebar__list__itemCon">
      <li
        onClick={() => setOpen((curr) => !curr)}
        className={
          pathname.includes(link.name.toLowerCase()) &&
          link.name !== "Dashboard"
            ? "sidebar__list__item active"
            : pathname === "/dashboard" && link.name === "Dashboard"
            ? "sidebar__list__item active"
            : "sidebar__list__item"
        }
        style={{ justifyContent: "space-between" }}
      >
        <div>
          <i style={{ marginRight: 15 }} className={link.iconClassName}></i>
          {link.name}
        </div>

        {open ? (
          <i className="fa fa-angle-up"></i>
        ) : (
          <i className="fa fa-angle-down"></i>
        )}
      </li>
      {open && (
        <div className="sidebar__list__dropdown">
          {link.dropDown?.map((item) => {
            return (
              <Link
                key={item.name}
                to={item.url === "" ? "/dashboard" : "/dashboard/" + item.url}
                onClick={() => drawer && setModalIsOpen(false)}
              >
                <span
                  className={
                    pathname.includes(item.url)
                      ? "sidebar__list__dropdown__item active"
                      : "sidebar__list__dropdown__item"
                  }
                >
                  {item.name}
                </span>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SideBarDropdown;
