import { useContext, useEffect, useState } from "react";
import Loading from "../components/Loading";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import Button from "../common/Button";
import TableMenu from "../components/TableMenu";
import { fetchCareers, fetchTraining } from "../api/dashboardApi";

const Training = () => {
  const { user } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [careers, setCareers] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
    // if (searchQuery) {
    //   handleSearch();
    // }
  };

  // const handleSearch = async () => {
  //   if (searchQuery && user?.id) {
  //     setLoading(true);
  //     try {
  //       // const res = await fetchBookingsBySearch(
  //       //   searchQuery,
  //       //   user?.accessToken,
  //       //   page
  //       // );

  //       // setCareers(res.data?.books);
  //       // setPageCount(res.data.totalPages);
  //       setLoading(false);
  //     } catch (err) {
  //       setLoading(false);
  //       const message =
  //         (err.response && err.response.data && err.response.data.message) ||
  //         err.message ||
  //         err.toString();
  //       toast.error(message);
  //     }
  //   }
  // };

  useEffect(() => {
    const handleFetchUsers = async () => {
      if (user?.id && searchQuery === "") {
        setLoading(true);
        try {
          const res = await fetchTraining(page, user?.accessToken, "All");

          setCareers(res.data?.trainings);
          setPageCount(res.data.totalPages);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, page, refresh]);

  return (
    <div className="staff">
      <div className="staff__main">
        <div className="dashboard__users__top">
          <h3 style={{ textTransform: "uppercase" }}>Training Applications</h3>
          {/* <div className="topbar__left__input">
            <input
              placeholder="Search by Name"
              type="text"
              // onChange={(e) => setSearchQuery(e.target.value)}
              value={searchQuery}
            />
            <i className="ti-search"></i>
          </div> */}
        </div>
        <div className="dashboard__users__table">
          <div
            className="dashboard__users__table__body"
            style={{ minWidth: 1000 }}
          >
            <div className="dashboard__users__table__row head">
              <div
                style={{ width: "30px" }}
                className="dashboard__users__table__row__head"
              >
                s/n
              </div>
              <div
                style={{ width: "120px" }}
                className="dashboard__users__table__row__head"
              >
                First Name
              </div>

              <div
                style={{ width: "120px" }}
                className="dashboard__users__table__row__head"
              >
                Last Name
              </div>
              <div
                style={{ width: "200px" }}
                className="dashboard__users__table__row__head"
              >
                Email
              </div>
              <div
                style={{ width: "200px" }}
                className="dashboard__users__table__row__head"
              >
                Program
              </div>
              <div
                style={{ width: "150px" }}
                className="dashboard__users__table__row__head"
              >
                Mobile Number
              </div>
              <div
                style={{ width: "100px" }}
                className="dashboard__users__table__row__head"
              >
                Date
              </div>
            </div>

            {loading ? (
              <Loading />
            ) : (
              careers?.map((staff, i) => {
                return (
                  <div key={staff?.id} className="dashboard__users__table__row">
                    <div
                      style={{ width: "30px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {(page - 1) * 10 + (i + 1)}
                    </div>
                    <div
                      style={{ width: "120px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.firstName}
                    </div>
                    <div
                      style={{ width: "120px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.lastName}
                    </div>
                    <div
                      style={{ width: "200px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.email}
                    </div>
                    <div
                      style={{ width: "200px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.program}
                    </div>
                    <div
                      style={{ width: "150px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.phone}
                    </div>
                    <div
                      style={{ width: "100px" }}
                      className="dashboard__users__table__row__data"
                    >
                      {staff?.createdAt?.slice(0, 10)}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>

        {pageCount > 1 && (
          <div style={{ marginTop: 40 }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              breakLabel="..."
              //   renderOnZeroPageCount={null}
              onPageChange={handlePageClick}
              // pageRangeDisplayed={itemsPerPage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Training;
