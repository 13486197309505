import { Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import TopBar from "../components/TopBar";

const DashboardLayout = () => {
  return (
    <div className="dashboardLayout">
      <TopBar />
      <Sidebar />
      <div className="dashboardLayout__main">
        <Outlet />
      </div>
    </div>
  );
};

export default DashboardLayout;
