import { useContext, useEffect, useState } from "react";
import {
  ErrorMessage,
  Input,
  Label,
  Select,
  Textarea,
} from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import Button from "../common/Button";
import Loading from "../components/Loading";
import { fetchPortfolio, updatePortfolio } from "../api/dashboardApi";
import { Context } from "../context/MainContext";
import { Link, useParams } from "react-router-dom";

const EditPortfolio = () => {
  const { user } = useContext(Context);
  const [inputError, setInputError] = useState({});
  const [fileName, setfileName] = useState("");
  const [name, setname] = useState("");
  const [url, seturl] = useState("");
  const [type, settype] = useState("");
  const [image, setimage] = useState("");
  const [shownImage, setshownImage] = useState("");
  const [uploadedFile, setuploadedFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const { id } = useParams();
  const [changeImage, setChangeImage] = useState(false);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file?.size > 1297150) {
      toast.warning("File too big, max size 1mb");
    } else {
      const file = e.target.files[0];
      const base64 = await convertToBase64(file);

      setChangeImage(true);
      setshownImage(base64);
      setfileName(file?.name);
      setuploadedFile(file);
    }
  };

  const validate = () => {
    let typeError = "";

    if (!type) {
      typeError = "type is required";
    }

    if (typeError) {
      setInputError((curr) => {
        return {
          ...curr,
          type: typeError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let formData = new FormData();

        if (changeImage) {
          formData.append("name", name);
          formData.append("type", type);
          formData.append("url", url);
          formData.append("uploadedFile", uploadedFile);
          formData.append("image", image);
        } else {
          formData.append("name", name);
          formData.append("type", type);
          formData.append("url", url);
          // formData.append("uploadedFile", uploadedFile);
        }

        updatePortfolio(id, formData, user?.accessToken);

        setLoading(false);
        toast.success("Portfolio Updated successfully");
        setChangeImage(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    const handleFetchBlog = async () => {
      if (user?.id) {
        setLoadingMain(true);
        try {
          const res = await fetchPortfolio(id, user?.accessToken);

          settype(res.data?.type);
          setname(res.data?.name);
          seturl(res.data?.url);
          setimage(res.data?.image);
          setshownImage(res.data?.image);

          setLoadingMain(false);
        } catch (err) {
          setLoadingMain(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchBlog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, id]);

  return (
    <div className="addBlog">
      <div className="addBlog__main">
        <h1 className="addBlog__main__title">
          Edit Portfolio
          <Link to="/dashboard/portfolio">
            <i className="ti-arrow-circle-left"></i>
          </Link>
        </h1>

        {loadingMain ? (
          <Loading />
        ) : (
          <>
            <div className="addBlog__main__form">
              <div className="addBlog__main__form__item">
                <Label htmlFor="title">
                  Type <span>*</span>
                </Label>
                <Select value={type} onChange={(e) => settype(e.target.value)}>
                  <option value="">--select type--</option>
                  <option value="top">Top Portfolios Worked with</option>
                  <option value="website">Website</option>
                  <option value="mobileApp">Mobile Application</option>
                  <option value="promoVid">Promotional Video Ads</option>
                  <option value="podcast">Business Podcast</option>
                  <option value="design">
                    Product Branding & UI/UX design
                  </option>
                  <option value="logoDesign">Logo Design</option>
                </Select>
                <ErrorMessage>{inputError?.type}</ErrorMessage>
              </div>

              <div className="addBlog__main__form__item">
                <Label htmlFor="title">Name</Label>

                <Input
                  width="300px"
                  id="title"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                />
              </div>

              <div className="addBlog__main__form__item">
                <Label htmlFor="tag">Link URL</Label>
                <Input
                  id="tag"
                  width="300px"
                  value={url}
                  onChange={(e) => seturl(e.target.value)}
                />
              </div>

              <div
                className="careerApp__main__input"
                style={{ marginBottom: "20px" }}
              >
                <Label htmlFor="cover">Image</Label>
                <br />
                <input
                  style={{ display: "none" }}
                  id="cover"
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                  accept="image/png, image/gif, image/jpeg, image/jpg"
                />
                <label className="careerApp__main__fileLabel" htmlFor="cover">
                  {!fileName ? "Choose File" : fileName}
                  {/* <span>Max file size: 300kb</span> */}
                </label>
                <ErrorMessage>{inputError?.uploadedFile}</ErrorMessage>
              </div>

              <img src={shownImage} alt="" style={{ width: 200 }} />
            </div>

            <div className="addBlog__main__form__btn">
              <Button onClick={handleSubmit}>
                {" "}
                {loading ? <Loading button={true} /> : "Edit"}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EditPortfolio;
