import React, { useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { deleteBlog } from "../api/dashboardApi";

const BlogItem = ({ blog, setRefresh, user }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (user?.id) {
      setLoading(true);
      try {
        const data = {
          url: blog?.coverUrl,
        };

        await deleteBlog(user?.accessToken, blog?.id, data);

        toast.success("Blog deleted successfully");
        setModalIsOpen(false);
        setLoading(false);
        setRefresh((curr) => !curr);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <>
      <ReactModal
        className="modalMain"
        overlayClassName="modal-overlayCenter"
        ariaHideApp={false}
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Cart Modal"
      >
        <div className="tableMenu__modal">
          <h4 style={{ marginBottom: 10 }}>Are you sure</h4>
          <p>You are about to delete a blog, this process cannot be undone</p>
          <div className="tableMenu__modal__btn">
            <button
              className="tableMenu__modal__btn__item"
              onClick={() => setModalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="tableMenu__modal__btn__item delete"
            >
              {loading ? <Loading button={true} /> : "Delete"}
            </button>
          </div>
        </div>
      </ReactModal>
      <div className="blog__main__list__item">
        <img src={blog?.coverUrl} alt="" />
        <div className="blog__main__list__item__date">
          <p>{blog?.tag}</p>
          <span>{blog?.createdAt?.slice(0, 10)}</span>
        </div>

        <h4>{blog?.title}</h4>
        <p className="blog__main__list__item__sum">
          {blog?.summary?.length > 190
            ? blog?.summary?.slice(0, 190) + "..."
            : blog?.summary}
        </p>

        <div className="blog__main__list__item__action">
          <Link to={"/dashboard/blogs/edit/" + blog?.id}>
            <i className="feather ft-edit"></i>
          </Link>
          <i className="ti-trash del" onClick={() => setModalIsOpen(true)}></i>
        </div>
      </div>
    </>
  );
};

export default BlogItem;
