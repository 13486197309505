import { useContext, useEffect, useState } from "react";
import {
  ErrorMessage,
  Input,
  Select,
  Textarea,
} from "../components/styledComponent/formInputs";
import profile from "../assets/images/svg/Profile.svg";
import Button from "../common/Button";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { userDetailsUpdateSuccess } from "../context/Action";
import { fetchStaff, updateProfile } from "../api/dashboardApi";
import { useParams } from "react-router-dom";

const EditStaff = () => {
  const [edit, setEdit] = useState(false);
  const { user, dispatch } = useContext(Context);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [role, setRole] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [address, setAddress] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const [mainloading, setMainLoading] = useState(false);
  const { id } = useParams();

  const validate = () => {
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";

    if (!firstName) {
      firstNameError = "first name is required";
    }
    if (!lastName) {
      lastNameError = "last name is required";
    }
    if (!email) {
      emailError = "email is required";
    }
    if (!email.includes("@")) {
      emailError = "email is invalid";
    }

    if (emailError || lastNameError || firstNameError) {
      setInputError((curr) => {
        return {
          ...curr,
          email: emailError,
          lastName: lastNameError,
          firstName: firstNameError,
        };
      });
      return false;
    }
    return true;
  };

  const handleEditProfile = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let data = {
          email,
          firstName,
          lastName,
          title,
          phone,
          gender,
          role,
          dob,
          maritalStatus,
          address,
        };

        await updateProfile(data, id, user?.accessToken);
        setLoading(false);
        toast.success("Staff update successfully");
        setEdit(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    const handleFetchUsers = async () => {
      if (user?.id) {
        setMainLoading(true);
        try {
          const res = await fetchStaff(id, user?.accessToken);

          setFirstName(res.data?.firstName);
          setLastName(res.data?.lastName);
          setEmail(res.data?.email);
          setAddress(res.data?.address ? res.data?.address : "");
          setTitle(res.data?.title ? res.data?.title : "");
          setPhone(res.data?.phone ? res.data?.phone : "");
          setGender(res.data?.gender ? res.data?.gender : "");
          setDob(res.data?.dob ? res.data?.dob : "");
          setMaritalStatus(
            res.data?.maritalStatus ? res.data?.maritalStatus : ""
          );
          setRole(res.data?.role ? res.data?.role : "");
          //   setStaff(res.data?.staff);
          setMainLoading(false);
        } catch (err) {
          setMainLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile">
      {mainloading ? (
        <Loading />
      ) : (
        <div className="profile__main">
          <h2
            className={
              edit ? "profile__main__title active" : "profile__main__title"
            }
          >
            {edit && "EDIT"} STAFF
            <i
              onClick={() => setEdit((curr) => !curr)}
              className="feather ft-edit"
            ></i>
          </h2>

          <div className="profile__main__con">
            <div
              className="profile__main__info"
              style={{ width: "100%", paddingLeft: 30 }}
            >
              <div className="profile__main__info__item">
                <b>First Name</b>

                {!edit ? (
                  <span>{firstName}</span>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Input
                      width="250px"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <ErrorMessage>{inputError?.firstName}</ErrorMessage>
                  </div>
                )}
              </div>
              <div className="profile__main__info__item">
                <b>Last Name</b>

                {!edit ? (
                  <span>{lastName}</span>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Input
                      width="250px"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <ErrorMessage>{inputError?.lastName}</ErrorMessage>
                  </div>
                )}
              </div>
              <div className="profile__main__info__item">
                <b>Title</b>

                {!edit ? (
                  <span>{title}</span>
                ) : (
                  <Input
                    width="250px"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                )}
              </div>
              <div className="profile__main__info__item">
                <b>Email</b>

                {!edit ? (
                  <span>{email}</span>
                ) : (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <Input
                      width="250px"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <ErrorMessage>{inputError?.email}</ErrorMessage>
                  </div>
                )}
              </div>
              <div className="profile__main__info__item">
                <b>Mobile Number</b>

                {!edit ? (
                  <span>{phone}</span>
                ) : (
                  <Input
                    width="250px"
                    type="number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                )}
              </div>

              <div className="profile__main__info__item">
                <b>Gender</b>

                {!edit ? (
                  <span>{gender}</span>
                ) : (
                  <Select
                    width="250px"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="--select gender--"></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Select>
                )}
              </div>
              <div className="profile__main__info__item">
                <b>Birth Date</b>

                {!edit ? (
                  <span>{dob}</span>
                ) : (
                  <Input
                    type="date"
                    width="250px"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                )}
              </div>
              <div className="profile__main__info__item">
                <b>Marital Status</b>

                {!edit ? (
                  <span>{maritalStatus}</span>
                ) : (
                  <Select
                    width="250px"
                    value={maritalStatus}
                    onChange={(e) => setMaritalStatus(e.target.value)}
                  >
                    <option value="--select marital status--"></option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                  </Select>
                )}
              </div>
              <div className="profile__main__info__item">
                <b>Role</b>

                {!edit ? (
                  <span>{role}</span>
                ) : (
                  <Select
                    width="250px"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="staff">Staff</option>
                    <option value="admin">Admin</option>
                  </Select>
                )}
              </div>
              <div
                //   style={{ margin: 30, marginTop: "0px" }}
                className="profile__main__info__item"
              >
                <b>Address</b>

                {!edit ? (
                  <span>{address}</span>
                ) : (
                  <Textarea
                    width="250px"
                    value={address}
                    rows={3}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                )}
              </div>
            </div>
          </div>

          {edit && (
            <div className="profile__main__btn">
              <Button height="40px" onClick={handleEditProfile}>
                {loading ? <Loading button={true} /> : "Save"}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EditStaff;
