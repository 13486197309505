import { useContext, useEffect, useState } from "react";
import {
  ErrorMessage,
  Input,
  Select,
  Textarea,
} from "../components/styledComponent/formInputs";
import profile from "../assets/images/svg/Profile.svg";
import Button from "../common/Button";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { userDetailsUpdateSuccess } from "../context/Action";
import { updateProfile } from "../api/dashboardApi";

const Profile = ({ dashboard }) => {
  const [edit, setEdit] = useState(false);
  const { user, dispatch } = useContext(Context);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [image, setImage] = useState("");
  const [changeImage, setChangeImage] = useState(false);
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [address, setAddress] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file?.size > 32240) {
      toast.warning("File too big, max size 30kb");
    } else {
      const base64 = await convertToBase64(file);

      setChangeImage(true);
      setImage(base64);
    }
  };

  const validate = () => {
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";

    if (!firstName) {
      firstNameError = "first name is required";
    }
    if (!lastName) {
      lastNameError = "last name is required";
    }
    if (!email) {
      emailError = "email is required";
    }
    if (!email.includes("@")) {
      emailError = "email is invalid";
    }

    if (emailError || lastNameError || firstNameError) {
      setInputError((curr) => {
        return {
          ...curr,
          email: emailError,
          lastName: lastNameError,
          firstName: firstNameError,
        };
      });
      return false;
    }
    return true;
  };

  const handleEditProfile = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let data = {};
        if (!changeImage) {
          data = {
            email,
            firstName,
            lastName,
            title,
            phone,
            gender,
            dob,
            maritalStatus,
            address,
          };
        } else {
          data = {
            email,
            firstName,
            lastName,
            image,
            title,
            phone,
            gender,
            dob,
            maritalStatus,
            address,
          };
        }

        await updateProfile(data, user?.id, user?.accessToken);

        setLoading(false);
        toast.success("Profile update successfully");
        const newUser = {
          id: user?.id,
          role: user?.role,
          accessToken: user?.accessToken,
          email,
          firstName,
          lastName,
          image,
          title,
          phone,
          gender,
          dob,
          maritalStatus,
          address,
        };
        dispatch(userDetailsUpdateSuccess(newUser));
        setChangeImage(false);
        setEdit(false);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    if (user?.id) {
      setFirstName(user?.firstName);
      setLastName(user?.lastName);
      setEmail(user?.email);
      setAddress(user?.address ? user?.address : "");
      setImage(user?.image ? user?.image : "");
      setTitle(user?.title ? user?.title : "");
      setPhone(user?.phone ? user?.phone : "");
      setGender(user?.gender ? user?.gender : "");
      setDob(user?.dob ? user?.dob : "");
      setMaritalStatus(user?.maritalStatus ? user?.maritalStatus : "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <div className="profile">
      <div className="profile__main">
        <h2
          className={
            edit ? "profile__main__title active" : "profile__main__title"
          }
        >
          ABOUT ME
          {!dashboard && (
            <i
              onClick={() => setEdit((curr) => !curr)}
              className="feather ft-edit"
            ></i>
          )}
        </h2>

        <div className="profile__main__con">
          <div className="profile__main__img">
            <img src={image ? image : profile} alt="" />
            {edit && (
              <input
                type="file"
                onChange={(e) => handleFileUpload(e)}
                accept="image/png, image/gif, image/jpeg, image/jpg"
              />
            )}
          </div>

          <div className="profile__main__info">
            <div className="profile__main__info__item">
              <b>First Name</b>

              {!edit ? (
                <span>{user?.firstName}</span>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    width="250px"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <ErrorMessage>{inputError?.firstName}</ErrorMessage>
                </div>
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Last Name</b>

              {!edit ? (
                <span>{user?.lastName}</span>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    width="250px"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                  <ErrorMessage>{inputError?.lastName}</ErrorMessage>
                </div>
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Title</b>

              {!edit ? (
                <span>{user?.title}</span>
              ) : (
                <Input
                  width="250px"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Email</b>

              {!edit ? (
                <span>{user?.email}</span>
              ) : (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Input
                    width="250px"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <ErrorMessage>{inputError?.email}</ErrorMessage>
                </div>
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Mobile Number</b>

              {!edit ? (
                <span>{user?.phone}</span>
              ) : (
                <Input
                  width="250px"
                  type="number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              )}
            </div>

            <div className="profile__main__info__item">
              <b>Gender</b>

              {!edit ? (
                <span>{user?.gender}</span>
              ) : (
                <Select
                  width="250px"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="--select gender--"></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Birth Date</b>

              {!edit ? (
                <span>{user?.dob}</span>
              ) : (
                <Input
                  type="date"
                  width="250px"
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                />
              )}
            </div>
            <div className="profile__main__info__item">
              <b>Marital Status</b>

              {!edit ? (
                <span>{user?.maritalStatus}</span>
              ) : (
                <Select
                  width="250px"
                  value={maritalStatus}
                  onChange={(e) => setMaritalStatus(e.target.value)}
                >
                  <option value="--select marital status--"></option>
                  <option value="Single">Single</option>
                  <option value="Married">Married</option>
                </Select>
              )}
            </div>
            <div
              //   style={{ margin: 30, marginTop: "0px" }}
              className="profile__main__info__item"
            >
              <b>Address</b>

              {!edit ? (
                <span>{user?.address}</span>
              ) : (
                <Textarea
                  width="250px"
                  value={address}
                  rows={3}
                  onChange={(e) => setAddress(e.target.value)}
                />
              )}
            </div>
          </div>
        </div>

        {edit && (
          <div className="profile__main__btn">
            <Button height="40px" onClick={handleEditProfile}>
              {loading ? <Loading button={true} /> : "Save"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
