import React, { useState } from "react";
import Button from "../common/Button";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordFp } from "../api/usersApi";
import { ErrorMessage } from "../components/styledComponent/formInputs";
import Loading from "../components/Loading";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const { id } = useParams();

  const validate = () => {
    let passwordError = "";
    let confirmPasswordError = "";

    if (password.length < 5) {
      passwordError = "Password  is required, minimum 5 characters";
    }
    if (confirmPassword !== password) {
      confirmPasswordError = "password and confirm password must be the same";
    }

    if (passwordError || confirmPasswordError) {
      setInputError((curr) => {
        return {
          ...curr,
          password: passwordError,
          confirmPassword: confirmPasswordError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSend = async () => {
    const checkValidate = validate();

    if (checkValidate) {
      if (password === confirmPassword) {
        setInputError({});
        setLoading(true);
        try {
          const data = {
            newPassword: password,
            token: id,
          };

          await resetPasswordFp(data);

          setLoading(false);
          toast.success("Reset Password successfully");
          setStep(2);
        } catch (err) {
          setLoading(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      } else {
        toast.error("new password and confirm new password musy be the same");
      }
    }
  };

  return (
    <div className="login">
      {step === 1 && (
        <>
          <h1 className="login__title" style={{ marginBottom: 30 }}>
            Reset Password
          </h1>

          <div className="login__form">
            <div>
              <div className="login__form__input">
                <input
                  type="password"
                  placeholder="New Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i className="ti-lock"></i>
              </div>
              <ErrorMessage>{inputError?.password}</ErrorMessage>
            </div>
            <div>
              <div className="login__form__input">
                <input
                  type="password"
                  placeholder="Confirm New Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <i className="ti-lock"></i>
              </div>
              <ErrorMessage>{inputError?.confirmPassword}</ErrorMessage>
            </div>

            <div style={{ marginTop: 20 }}>
              <Button type="btnMainAlt" width="100%" onClick={handleSend}>
                {loading ? (
                  <Loading button={true} />
                ) : (
                  <>
                    Submit
                    <i className="ti-arrow-right"></i>
                  </>
                )}
              </Button>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <h1 className="login__title suc" style={{}}>
            Reset Password Successful
          </h1>

          <p className="login__subtitle">Proceed to Login</p>

          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 40 }}
          >
            <Link to="/login">
              <Button type="btnMainAlt" width="200px">
                Login
                <i className="ti-arrow-right"></i>
              </Button>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

export default ResetPassword;
