import App from "./App";
import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/css/themify-icons.css";
import "./assets/styles/css/feather.css";
import "./assets/styles/css/font-awesome.min.css";
import { ContextProvider } from "./context/MainContext";
import { SocketContextProvider } from "./context/SocketContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ContextProvider>
      <SocketContextProvider>
        <App />
      </SocketContextProvider>
    </ContextProvider>
  </React.StrictMode>
);
