import React, { useContext, useEffect, useState } from "react";
import {
  ErrorMessage,
  Input,
  Textarea,
} from "../components/styledComponent/formInputs";
import Button from "../common/Button";
import ReactPaginate from "react-paginate";
import { Context } from "../context/MainContext";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { fetchMessage, sendMessage } from "../api/dashboardApi";

const Message = () => {
  const { user } = useContext(Context);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [inputError, setInputError] = useState({});
  const [messages, setMessages] = useState([]);

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };

  const validate = () => {
    let titleError = "";
    let textError = "";
    // let youtubeError = "";

    if (!title) {
      titleError = "title is required";
    }
    if (!text) {
      textError = "text is required";
    }
    // if (!youtube) {
    //   youtubeError = "youtube link is required";
    // }

    if (titleError || textError) {
      setInputError((curr) => {
        return {
          ...curr,
          title: titleError,
          text: textError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        const data = {
          text,
          title,
        };

        await sendMessage(user?.accessToken, data);

        setLoading(false);
        toast.success("Message Sent successfully");
        setTitle("");
        setText("");
        // setStep(2);
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  useEffect(() => {
    const handleFetchUsers = async () => {
      if (user?.id) {
        setLoadingMain(true);
        try {
          const res = await fetchMessage(page, user?.accessToken);

          setMessages(res.data?.messages);
          setPageCount(res.data.totalPages);
          setLoadingMain(false);
        } catch (err) {
          setLoadingMain(false);
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            err.message ||
            err.toString();
          toast.error(message);
        }
      }
    };

    handleFetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, page]);

  return (
    <div className="message">
      <div className="message__main">
        {user?.role === "admin" && (
          <div className="message__main__form">
            <div className="message__main__form__item">
              <Input
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <ErrorMessage>{inputError?.title}</ErrorMessage>
            </div>

            <div className="message__main__form__item">
              <Textarea
                rows={4}
                placeholder="Type messsage"
                value={text}
                onChange={(e) => setText(e.target.value)}
              ></Textarea>
              <ErrorMessage>{inputError?.text}</ErrorMessage>
            </div>

            <div className="message__main__form__btn">
              <Button onClick={handleSubmit}>
                {loading ? <Loading button={true} /> : "Send"}
              </Button>
            </div>
          </div>
        )}

        <div className="message__main__list">
          {loadingMain ? (
            <Loading />
          ) : (
            messages?.map((item, i) => {
              return (
                <div className="message__main__list__item">
                  <h4>{item?.title}</h4>
                  <p>{item?.text}</p>
                </div>
              );
            })
          )}
        </div>
        {pageCount > 1 && (
          <div style={{ marginTop: 40 }}>
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={pageCount}
              breakLabel="..."
              //   renderOnZeroPageCount={null}
              onPageChange={handlePageClick}
              // pageRangeDisplayed={itemsPerPage}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttn"}
              nextLinkClassName={"nextBttn"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Message;
