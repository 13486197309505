import { useContext, useState } from "react";
import {
  ErrorMessage,
  Input,
  Label,
  Select,
  Textarea,
} from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import Button from "../common/Button";
import Loading from "../components/Loading";
import { addBlog, addPortfolio } from "../api/dashboardApi";
import { Context } from "../context/MainContext";
import { Link } from "react-router-dom";

const AddPortfolio = () => {
  const { user } = useContext(Context);
  const [inputError, setInputError] = useState({});
  const [fileName, setfileName] = useState("");
  const [name, setname] = useState("");
  const [url, seturl] = useState("");
  const [type, settype] = useState("");
  const [uploadedFile, setuploadedFile] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];

    if (file?.size > 1297150) {
      toast.warning("File too big, max size 1mb");
    } else {
      setfileName(file?.name);
      setuploadedFile(file);
    }
  };

  const validate = () => {
    let typeError = "";

    if (!type) {
      typeError = "type is required";
    }

    if (typeError) {
      setInputError((curr) => {
        return {
          ...curr,
          type: typeError,
        };
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        let formData = new FormData();

        formData.append("name", name);
        formData.append("type", type);
        formData.append("url", url);
        formData.append("uploadedFile", uploadedFile);

        addPortfolio(formData, user?.accessToken);

        setLoading(false);
        toast.success("Portfolio added successfully");
        // setStep(2);
        setname("");
        settype("");
        seturl("");
        setuploadedFile("");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };

  return (
    <div className="addBlog">
      <div className="addBlog__main">
        <h1 className="addBlog__main__title">
          Add Portfolio
          <Link to="/dashboard/portfolio">
            <i className="ti-arrow-circle-left"></i>
          </Link>
        </h1>
        <div className="addBlog__main__form">
          <div className="addBlog__main__form__item">
            <Label htmlFor="title">
              Type <span>*</span>
            </Label>
            <Select value={type} onChange={(e) => settype(e.target.value)}>
              <option value="">--select type--</option>
              <option value="top">Top Portfolios Worked with</option>
              <option value="website">Website</option>
              <option value="mobileApp">Mobile Application</option>
              <option value="promoVid">Promotional Video Ads</option>
              <option value="podcast">Business Podcast</option>
              <option value="design">Product Branding & UI/UX design</option>
              <option value="logoDesign">Logo Design</option>
            </Select>
            <ErrorMessage>{inputError?.type}</ErrorMessage>
          </div>

          <div className="addBlog__main__form__item">
            <Label htmlFor="title">Name</Label>

            <Input
              width="300px"
              id="title"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>

          <div className="addBlog__main__form__item">
            <Label htmlFor="tag">Link URL</Label>
            <Input
              id="tag"
              width="300px"
              value={url}
              onChange={(e) => seturl(e.target.value)}
            />
          </div>

          <div
            className="careerApp__main__input"
            style={{ marginBottom: "20px" }}
          >
            <Label htmlFor="cover">Image</Label>
            <br />
            <input
              style={{ display: "none" }}
              id="cover"
              type="file"
              onChange={(e) => handleFileUpload(e)}
              accept="image/png, image/gif, image/jpeg, image/jpg"
            />
            <label className="careerApp__main__fileLabel" htmlFor="cover">
              {!fileName ? "Choose File" : fileName}
              {/* <span>Max file size: 300kb</span> */}
            </label>
            <ErrorMessage>{inputError?.uploadedFile}</ErrorMessage>
          </div>
        </div>

        <div className="addBlog__main__form__btn">
          <Button onClick={handleSubmit}>
            {" "}
            {loading ? <Loading button={true} /> : "Add"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddPortfolio;
