import React from "react";
import Profile from "./Profile";

const StaffDashboard = () => {
  return (
    <div>
      <Profile dashboard={true} />
    </div>
  );
};

export default StaffDashboard;
