import { useContext, useState } from "react";
import Button from "../common/Button";
import { ErrorMessage, Input } from "../components/styledComponent/formInputs";
import { toast } from "react-toastify";
import { changePassword } from "../api/dashboardApi";
import { Context } from "../context/MainContext";
import Loading from "../components/Loading";

const ChangePassword = () => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [inputError, setInputError] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useContext(Context);

  const validate = () => {
    let passwordError = "";
    let newPasswordError = "";
    let newPasswordConfirmError = "";

    if (!password) {
      passwordError = "old password is required";
    }
    if (newPassword.length < 5) {
      newPasswordError = "new password is required, minimum 5 characters";
    }

    if (newPassword !== confirmNewPassword) {
      newPasswordConfirmError =
        "new password and confirm new password must be the same";
    }

    if (passwordError || newPasswordError || newPasswordConfirmError) {
      setInputError((curr) => {
        return {
          ...curr,
          password: passwordError,
          newPassword: newPasswordError,
          confirmNewPassword: newPasswordConfirmError,
        };
      });
      return false;
    }
    return true;
  };

  const handleChange = async () => {
    const checkValidate = validate();
    if (checkValidate) {
      setInputError({});
      setLoading(true);
      try {
        const data = {
          password,
          newPassword,
        };

        await changePassword(data, user?.id, user?.accessToken);

        setLoading(false);
        toast.success("Password Changed successfully");
        setPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } catch (err) {
        setLoading(false);
        const message =
          (err.response && err.response.data && err.response.data.message) ||
          err.message ||
          err.toString();
        toast.error(message);
      }
    }
  };
  return (
    <div className="changePassword">
      <div className="changePassword__main">
        <h2>CHANGE ACCOUNT PASSWORD</h2>

        <div className="changePassword__main__form">
          <div className="changePassword__main__form__input">
            <Input
              type="password"
              placeholder="Old password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ErrorMessage>{inputError?.password}</ErrorMessage>
          </div>
          <div className="changePassword__main__form__input">
            <Input
              type="password"
              placeholder="New password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <ErrorMessage>{inputError?.newPassword}</ErrorMessage>
          </div>
          <div className="changePassword__main__form__input">
            <Input
              type="password"
              placeholder="Confirm New password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
            <ErrorMessage>{inputError?.confirmNewPassword}</ErrorMessage>
          </div>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="btnMainAlt" height="40px" onClick={handleChange}>
              {loading ? <Loading button={true} /> : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
